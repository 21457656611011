

import { mapGetters } from 'vuex';
import aosMixin from '~/mixins/aos';

export default {

    name: 'PageFooter',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },

    mixins: [
        aosMixin
    ],

    computed: {

        ...mapGetters(['isAuthenticated']),

        copyrightLabel() {
            return '&copy; ' + new Date().getFullYear() + ' Paramount Global. All rights reserved.'
        }

    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
};

