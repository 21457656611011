// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/bg-main-mobile.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/bg-main-mobile_2x.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/images/bg-main.jpg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/images/bg-main_2x.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#app[data-v-fc58d0f6]{font-family:\"Neue Plak Regular\",sans-serif;font-size:1rem;font-weight:400;line-height:1.337;text-align:center;color:#121314;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;position:relative}.app-bg[data-v-fc58d0f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50% 0;background-repeat:no-repeat;width:100vw;height:100vh;position:fixed;top:0;left:0;z-index:-1;pointer-events:none}@media only screen and (-webkit-min-device-pixel-ratio:1.25),only screen and (min-device-pixel-ratio:1.25),only screen and (min-resolution:1.25dppx){.app-bg[data-v-fc58d0f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:cover}}@media(min-width:576px){.app-bg[data-v-fc58d0f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:cover}}@media only screen and (min-width:576px)and (-webkit-min-device-pixel-ratio:1.25),only screen and (min-width:576px)and (min-device-pixel-ratio:1.25),only screen and (min-width:576px)and (min-resolution:1.25dppx){.app-bg[data-v-fc58d0f6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");background-size:cover}}.page-enter-active[data-v-fc58d0f6],.page-leave-active[data-v-fc58d0f6]{transition:all .4s}.page-enter-from[data-v-fc58d0f6],.page-leave-to[data-v-fc58d0f6]{opacity:0;filter:blur(1rem)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
