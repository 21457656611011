import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default () => new Vuex.Store({
    state: () => ({}),
    getters: {
        isAuthenticated(state) {
            return state.auth.loggedIn;
        },
        loggedInUser(state) {
            //console.log('state', state);
            //console.log('token', localStorage.getItem('auth._token.local'))
            return state.auth.user;
        }
    },
    mutations: {},
    actions: {},
    modules: {}
});
