
//import BrandLogoImage from '@/assets/images/nickelodeon-logo-header.svg';
//import BrandLogoImagePng from '@/assets/images/nickelodeon-logo-header-v2.png';
//import BrandLogoImagePng2x from '@/assets/images/nickelodeon-logo-header-v2_2x.png';

let vm = {};

export default {

    name: 'BrandLogo',

    props: {
        logoImage: {
            type: String,
            default: ''
        },
        logoImage2x: {
            type: String,
            default: ''
        }
    },
    
    data() {
        return {
            componentName: this.$options.name + ' Component',
            isLoaded: false
        };
    },

    computed: {
        logoImageSrcSet() {
            let _srcset = this.logoImage + ' 1x';
            if (this.logoImage2x) {
                _srcset += ', ' + this.logoImage2x + ' 2x';
            }
            return _srcset;
        }
    },
    
    created() {
        
    },
    
    mounted() {

    },
    
    methods: {
        
    }
    
};

