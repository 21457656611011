import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faDownload,
    faEye,
    faEyeSlash,
    faVolumeXmark,
    faVolumeHigh,
    faExpand,
    faCompress,
    faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import {
    faYoutube,
    faXTwitter,
    faFacebook,
    faInstagram,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faDownload,
    faEye,
    faEyeSlash,
    faVolumeXmark,
    faVolumeHigh,
    faExpand,
    faCompress,
    faUpRightFromSquare,
    faYoutube,
    faXTwitter,
    faFacebook,
    faInstagram,
    faTiktok
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
