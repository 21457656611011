

export default {

    name: 'BreakpointDebugger',

    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }

};

