

export default {

    name: 'BrandTaglineText',

    props: {
        taglineText: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }

};

