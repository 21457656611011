

import { mapGetters } from 'vuex';

import BreakpointDebugger from '@/components/utilities/BreakpointDebugger';
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
//import PageBlade from '@/components/PageBlade';

export default {

    name: 'App',

    components: {
        BreakpointDebugger,
        PageHeader,
        PageFooter/*,
        PageBlade*/
    },

    data() {
        return {
            bpDebug: (process.env.NODE_ENV !== 'production'),
            appReady: false
        };
    },

    computed: {
        ...mapGetters(['isAuthenticated'])
    },

    created() {

    },

    mounted() {
        let vm = this;

        vm.$nextTick(() => {
            vm.appReady = true;
        });
    },

    methods: {

    }

};
