

import { mapGetters } from 'vuex';

import BrandingContent from '~/apollo/queries/branding/Branding';
import BrandLogo from '@/components/svg/BrandLogo';
import BrandTaglineText from '@/components/BrandTaglineText';
import PageNavMobile from '@/components/PageNavMobile';
import MenuList from "@/apollo/queries/menu/MenuList";

export default {

    name: 'PageNav',

    components: {
        BrandLogo,
        /*BrandTagline,*/
        BrandTaglineText,
        PageNavMobile
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            menuBreakpoint: 992,
            scrollTriggerPoint: 24,
            configuration: {},
            query: ''
        };
    },

    apollo: {
        configuration: {
            prefetch: true,
            query: BrandingContent
        }
    },

    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    
    created() {

    },
    
    mounted() {
        //window.addEventListener('resize', this.handleWindowResize, false);
        document.addEventListener('click', this.handleDocClick, false);
        document.addEventListener('scroll', this.handleDocScroll, false);
    },
    
    methods: {

        /**
         * @method handleDocClick
         * @description
         * @param {Object} evt
         */
        handleDocClick(evt) {
            let toggler = document.querySelector('button.navbar-toggler');
            // trigger nav toggle if open
            if (toggler && !toggler.classList.contains('collapsed')) {
                toggler.click();
            }
        },

        handleDocScroll(evt) {
            let nav = document.querySelector('.page-nav.navbar');
            const curScrollPos = window.pageYOffset;
            //console.log('curScrollPos', curScrollPos);

            if (curScrollPos > this.scrollTriggerPoint) {
                nav.classList.add('scrolled');
            } else {
                nav.classList.remove('scrolled');
            }
        },

        /**
         * @method handleWindowResize
         * @description Window resize event handler.
         * @param {Object} evt
         */
        /*handleWindowResize(evt) { // eslint-disable-line no-unused-vars
            let mql = window.matchMedia('(min-width: 992px)');
            let navToggleEl = document.querySelector('.navbar-toggler');

            // check if we match the media query && toggle element expanded attribute is currently true
            if (mql.matches && (navToggleEl && navToggleEl.getAttribute('aria-expanded') === 'true')) {
                navToggleEl.click();
            }
        }*/

    },

    beforeDestroy() {
        document.removeEventListener('click', this.handleDocClick, false);
    }

};

