

import { mapGetters } from 'vuex';

import PageNavSidebar from '@/components/PageNavSidebar';

let vm = {};

export default {

    name: 'PageNavMobile',

    components: {
        PageNavSidebar
    },

    data() {
        return {
            componentName: this.$options.name + ' Component',
            navbarMobile: null,
            navbarWrapper: null,
            brandLogo: null,
            brandTagline: null,
            scrollIcon: null
        };
    },

    computed: {
        ...mapGetters(['isAuthenticated'])
    },
    
    created() {
        vm = this;
        this.$nuxt.$on('click::logout', (evt) => {
            this.handleNavbarHide(evt);
        });
    },
    
    mounted() {
        this.navbarMobile = document.querySelector('#navbarMobile');
        this.navbarWrapper = document.querySelector('.page-nav.navbar');
        this.brandLogo = document.querySelector('.brand-logo');
        this.brandTagline = document.querySelector('.brand-tagline');
        this.scrollIcon = document.querySelector('.icon-scroll');

        if (this.navbarMobile) {
            this.navbarMobile.addEventListener('show.bs.collapse', this.handleNavbarShow, false);
            this.navbarMobile.addEventListener('hide.bs.collapse', this.handleNavbarHide, false);
        }


    },
    
    methods: {

        /**
         * @method handleNavbarShow
         * @description Navbar show event handler.
         */
        handleNavbarShow(evt) {  // eslint-disable-line no-unused-vars
            if (this.brandLogo) {
                this.brandLogo.classList.add('menu-expanded');
            }
            if (this.brandTagline) {
                this.brandTagline.classList.add('menu-expanded');
            }
            if (this.scrollIcon) {
                this.scrollIcon.classList.add('menu-expanded');
            }
            if (this.navbarWrapper) {
                this.navbarWrapper.classList.add('menu-show');
                this.navbarWrapper.classList.replace('navbar-dark', 'navbar-light');
                this.navbarWrapper.classList.replace('bg-primary', 'bg-primary');
            }
        },

        /**
         * @method handleNavbarHide
         * @description Navbar hide event handler.
         */
        handleNavbarHide(evt) {  // eslint-disable-line no-unused-vars
            if (this.brandLogo) {
                this.brandLogo.classList.remove('menu-expanded');
            }
            if (this.brandTagline) {
                this.brandTagline.classList.remove('menu-expanded');
            }
            if (this.scrollIcon) {
                this.scrollIcon.classList.remove('menu-expanded');
            }
            if (this.navbarWrapper) {
                this.navbarWrapper.classList.remove('menu-show');
                this.navbarWrapper.classList.replace('navbar-light', 'navbar-dark');
                this.navbarWrapper.classList.replace('bg-primary', 'bg-primary');
            }
        }

    },

    destroyed() {
        if (this.navbarMobile) {
            this.navbarMobile.addEventListener('show.bs.collapse', this.handleNavbarShow, false);
            this.navbarMobile.addEventListener('hide.bs.collapse', this.handleNavbarHide, false);
        }
    }

};

