

import PageNav from '@/components/PageNav';

export default {

    name: 'PageHeader',

    components: {
        PageNav
    },

    data() {
        return {
            componentName: this.$options.name + ' Component'
        };
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
};

